import { CriteriaBase } from '@/shared/models';

/**
 * Modèle pour les critères de recherche dans le cache.
 * */
export class CacheCriteria extends CriteriaBase {

    /**
     * La recherche saisie.
     */
    public recherche: string = '';
}