import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component/lib/util';
import template from './Cache.Template.vue';
import { CacheItem } from '@/models';
import { ApiService } from '@/services/base/ApiService';
import { DataTableHeader } from '@/shared/models';
import { GrilleMixin } from '@/shared/mixins/Grille.mixin';
import { PagingAndSorting } from '@/shared/models';
import { UserProfile } from '@/store/modules/auth/types';
import { Getter } from 'vuex-class';
import { AuthStoreMethods } from '@/store/modules/auth/AuthStore';
import { CacheCriteria } from './models/CacheCriteria.model';

@Component({
    ...template,
    name: 'Cache',
})
export default class Cache extends mixins(GrilleMixin)  {
    public headers: DataTableHeader[] = [];
    public caches: CacheItem[];
    public canEdit: boolean = true;

    /**
     * Le nombre total d'utilisateurs.
     */
    public totalUtilisateurs: number = 0;

    /**
     * Le critère de recherche de la grille.
     */
    public cacheCriteria: CacheCriteria = new CacheCriteria();
    /**
     * Gère la visibilité du loader.
     */
    public loading: boolean = false;

    // Permet de savoir si c'est un administrateur informatique.
    public estAdministrateurInformatique: boolean = false;

    // Permet se récupérer le profil de l'utilisateur connecté.
    @Getter(AuthStoreMethods.USER_PROFILE)
    private getUserProfile: Promise<UserProfile>;

    constructor() {
        super();
        this.headers = [];
        this.caches = [];
    }
    public created() {
        this.initialize();
        this.getData();
    }
    /**
     * Méthode appelée une fois la vue chargée.
     */
    public mounted(): void {
        this.getUserProfile.then((profile: UserProfile) => {
            if (profile) {
                this.estAdministrateurInformatique = profile.isSuperAdmin;
            }
        });     
    }
    public initialize() {
        this.headers = [
            { text: 'Région', sortable: false },
            { text: 'Clé du cache', sortable: false },
            { text: 'Valeur', sortable: false },
            { text: 'Actions', sortable: false },
        ];
        this.caches = [];
    }

    /*
     * Récupération des données.
     */
    public getData() {
        this.cacheCriteria.pagingAndSorting = PagingAndSorting.buildFromPaginationObject(this.pagination);
        this.getDataFromCache(this.cacheCriteria);
    }

    public rechercherDansLeCache() {
        // Réinitialise la pagination avant de lancer la recherche.
        this.cacheCriteria.pagingAndSorting = new PagingAndSorting();
        this.getData();
    }

    public getDataFromCache(criteria: CacheCriteria): void {
        this.loading = true;
        const fetchDataFromCacheApi = new ApiService<CacheItem>('cacheManager/obtenirTous');

        fetchDataFromCacheApi.getWhere(criteria.queryString).then((response) => {
            this.caches = response.data.data;
            this.totalUtilisateurs = response.data.totalCount;
            this.$set(this.pagination, 'totalItems', this.totalUtilisateurs);
        })
        .finally(() => this.loading = false);

    }

    /**
     * Suppression d'un item dans le cache.
     * @param item CacheItem.
     */
    public removeFromCache(item: CacheItem): void {
        if (item && item.key && item.region) {
            if (confirm('Etes vous sûr de vouloir supprimer cet élement ?')) {
                this.loading = true;
                const index = this.caches.indexOf(item);
                this.$http.delete(`/CacheManager/${item.region}/${item.key}`).then((result) => {
                    if (!result.data.isError) {
                        this.caches.splice(index, 1);
                    }
                }).finally(() => this.loading = false);
            }
        }
    }
}
